<template>
  <div id="stars"></div>
  <div id="stars2"></div>
  <div id="stars3"></div>
  <div class="title">
    <h1>Adiense</h1>
    <h1>Site in progress...</h1>
    <img width="350" height="350" src="https://cdn.cheatcat.com/community/public/ugc/TwwpQ3ZmhPAg4mE9/P3QJPmo50GzHsNwQpK.png" />
    <h1>Email - contact@adiense.com.</h1>
    <h1>Contact via <a href="https://telegram.me/adiense">telegram</a>.</h1>
  </div>
</template>

<script>

export default {
  name: 'StandBy',
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
img {
  margin: 10px 0 10px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
